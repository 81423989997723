@font-face {
  font-family: on-document;
  src: url("on-document-groups.1f1ce384.eot");
  src: url("on-document-groups.1f1ce384.eot#iefix") format("embedded-opentype"), url("on-document-groups.5010f14e.ttf") format("truetype"), url("on-document-groups.224ceae3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.icon-scissors:before {
  content: "";
}

.icon-link:before {
  content: "";
}

/*# sourceMappingURL=index.08cdeb4a.css.map */
